[class^="icon-"] {
  background-image: url("../images/sprite.png"); }

.fill-star,
.unfill-star,
.countries sup,
.smk_accordion .accordion_in .acc_head .acc_icon_expand,
.call-us .ui-block-a span,
.call-us .ui-block-b span,
.call-us .ui-block-c span,
.define-flow li span:before,
.suggest-list li span,
.level-control:after,
.payment-summary .list-item li .item:before,
.search-icon-tab, .icon-loc, .mail-iconStrock,
.logo {
  background-image: url("../images/sprite.png"); }

.custom-select {
  background-image: url("../images/dropdown.svg"); }
